import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const ChartsModal = Loadable(() => import("./ChartsModal"));

function ChartsModalAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const ChartsModalContainer = (props) => {
  return <ChartsModal {...props} fallback={<ChartsModalAsyncLoading />} />;
};

export default ChartsModalContainer;
